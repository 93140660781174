<template>
  <div>
    <p class="i-hide-text-footer clearfix mb-0">
      <span class="float-md-left d-none d-sm-none d-md-block mt-25">
        <span class="ml-25">
          <strong class="text-dark">Sport Business Intelligence Inc. </strong> © {{ new Date().getFullYear() }}
        </span>
        <span class="d-none d-sm-inline-block">&nbsp;| {{ $t('generic.rightsReserved') }}</span>
      </span>
    </p>
    <div class="d-block d-sm-block d-md-none">
      <b-row>
        <b-col
          class="cursor-pointer"
        >
          <message-modal />
        </b-col>
        <b-col
          class="cursor-pointer"
        >
          <notification-modal
            @show-event="showModuleEvent = true"
          />
          <!-- <i class="icon-0-icons-dark-bell" /> -->
        </b-col>
        <b-col
          class="cursor-pointer"
          @click="$router.push({ name: 'account-settings', params: { id: 1} })"
        >
          <i class="icon-0-icons-dark-avatar icon-footer-size" />
        </b-col>
        <b-col
          class="cursor-pointer"
        >
          <events-calendar-modal
            v-model="showModuleEvent"
          />
        </b-col>
        <b-col
          class="cursor-pointer"
        >
          <news-modal />
          <!-- <i class="icon-0-icons-dark-newsfeed" /> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import NotificationModal from './components/NotificationModal.vue'
import NewsModal from './components/NewsModal.vue'
import EventsCalendarModal from './components/EventsCalendarModal.vue'
import MessageModal from './components/MessageModal.vue'

export default {
  components: {
    MessageModal,
    NotificationModal,
    NewsModal,
    EventsCalendarModal,
  },
  data() {
    return {
      showModuleEvent: false,
      isActiveModal: false,
    }
  },
  methods: {
    //
  },
}
</script>
<style lang="scss" scoped>
</style>
