<template>
  <div class="d-flex justify-content-center align-items-center">
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-close-on-backdrop
      no-header
      right
    >
      <!-- @change="$emit('is-event-handler-sidebar-active')" -->
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ event.hash ? $t('generic.upload') : $t('generic.add') }} {{ $t('generic.event') }}
          </h5>
          <div>
            <feather-icon
              v-if="event.hash"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event', event.hash); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="$emit('is-event-handler-sidebar-active')"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            ref="refFormEvent"
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- @reset.prevent="resetForm" -->

            <!-- Title -->
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required|max:254|alphaNumSpacesRegex"
            >
              <b-form-group
                :label="$t('generic.title')"
                label-for="event-title"
              >
                <b-form-input
                  id="event-title"
                  v-model="event.title"
                  autofocus
                  trim
                  size="sm"
                  :placeholder="$t('generic.titleEvent')"
                  :state="errors.length > 0 ? false:null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="{ errors }"
              name="initDate"
              rules="required"
            >

              <b-form-group
                :label="$t('generic.initDate')"
                label-for="start-date"
                :state="errors.length > 0 ? false:null"
              >
                <el-date-picker
                  v-model="event.init_date"
                  type="datetime"
                  size="small"
                  style="width: 100%"
                  format="yyyy/MM/dd hh:mm:ss"
                  :disabled-date="disabledDate"
                  :placeholder="$t('member.form.placeholder.selectDate')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="{ errors }"
              name="endDate"
              rules="required"
            >
              <b-form-group
                :label="$t('generic.endDate')"
                label-for="end-date"
                :state="errors.length > 0 ? false:null"
              >
                <el-date-picker
                  v-model="event.end_date"
                  type="datetime"
                  size="small"
                  style="width: 100%"
                  format="yyyy/MM/dd hh:mm:ss"
                  :disabled-date="disabledDate"
                  :placeholder="$t('member.form.placeholder.selectDate')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- All Day -->
            <!-- <b-form-group>
              <b-form-checkbox
                v-model="event.all_day"
                name="check-button"
                switch
                inline
                :checked="event.all_day"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('generic.allDay') }}
              </b-form-checkbox>
            </b-form-group> -->

            <!-- Event URL -->
            <validation-provider
              #default="{ errors }"
              name="Event URL"
              rules="url"
            >

              <b-form-group
                :label="$t('generic.eventURL')"
                label-for="event-url"
              >
                <b-form-input
                  id="event-url"
                  v-model="event.url"
                  type="url"
                  size="sm"
                  :state="errors.length > 0 ? false:null"
                  placeholder="htttps://www.google.com"
                  trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Include everyone -->
            <b-form-group>
              <b-form-checkbox
                v-model="event.include_everyone"
                name="check-button"
                switch
                inline
                :checked="event.include_everyone"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t('generic.includeEveryOne') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- Guests -->
            <b-form-group
              :label="$t('generic.addGuest')"
              label-for="add-guests"
            >
              <v-select
                v-model="event.include_users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                :options="userList"
                label="name"
                input-id="add-guests"
                :disabled="event.include_everyone === true"
                class="select-size-sm"
              >

                <template #option="{ name }">
                  <!-- <b-avatar
                    size="sm"
                    :src="avatar"
                  /> -->
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>

                <template #selected-option="{ name }">
                  <!-- <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  /> -->
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>

            <!-- Exclude Guests -->
            <b-form-group
              :label="$t('generic.excludeGuest')"
              label-for="exclude-guests"
            >
              <v-select
                v-model="event.exclude_users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                :options="userList"
                label="name"
                input-id="exclude-guests"
                class="select-size-sm"
                :disabled="event.include_users.length > 0 || event.include_everyone === false"
              >

                <template #option="{ name }">
                  <!-- <b-avatar
                    size="sm"
                    :src="avatar"
                  /> -->
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>

                <template #selected-option="{ name }">
                  <!-- <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  /> -->
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>

            <!-- Location -->
            <b-form-group
              :label="$t('generic.location')"
              label-for="event-location"
            >
              <b-form-input
                id="event-location"
                v-model="event.location"
                trim
                size="sm"
                :placeholder="$t('generic.eventLocation')"
              />
            </b-form-group>

            <!-- Textarea -->
            <b-form-group
              :label="$t('generic.description')"
              label-for="event-description"
            >
              <b-form-textarea
                id="event-description"
                v-model="event.description"
                maxlength="254"
              />
            </b-form-group>

            <b-form-group label="Color">
              <div
                v-for="(color, index) in eventColors"
                :key="color.hash"
                class="p-1 d-inline-block rounded mr-1 cursor-pointer"
                :class="[{'border border-light': !index}, {'mark-active': event.color_hash === color.hash}]"
                :style="{'background': `${color.code}`}"
                @click="event.color_hash = color.hash"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                pill
                class="mr-2"
                @click="$emit('is-event-handler-sidebar-active')"
              >
                {{ $t('buttons.cancel') }}
              </b-button>
              <b-button
                v-if="event.hash == null && checkPermission(['CREATE_EVENTS', 'ALLOW_ALL']) || event.hash != null && checkPermission(['EDIT_EVENTS', 'ALLOW_ALL'])"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                pill
                class="mr-2"
                type="submit"
                :style="colorPrimaryBtn"
              >
                {{ event.hash ? $t('generic.upload') : $t('generic.add') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { colors } from '@/services/catalogsService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    // event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    clubInfo: {
      type: Object,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone: 'UTC',
      eventColors: [],
      // eslint-disable-next-line consistent-return
      disabledDate(time) {
        const today = new Date().setHours(0, 0, 0, 0)

        const dateAux = Math.floor(time.getTime() / 86400000)
        const todayAux = Math.floor(today / 86400000)

        if (dateAux < todayAux) {
          return time
        }
      },
    }
  },
  watch: {
    'event.include_everyone': function eventIncludeEveryone(status) {
      if (status === true) {
        this.event.include_users = []
      } else {
        this.event.exclude_users = []
      }
    },
    'event.init_date': function eventInitDate(date, oldDate) {
      if (oldDate && this.event.hash == null) {
        this.event.end_date = null
      }
    },
  },
  mounted() {
    this.fetchColors()
  },
  methods: {
    fetchColors() {
      colors().then(({ data }) => {
        this.eventColors = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    onSubmit() {
      const eventData = JSON.parse(JSON.stringify(this.event))

      if (this.event.color_hash == null) {
        this.event.color_hash = this.eventColors[0].hash
      }

      if (this.clubInfo.hash) {
        this.event.club_hash = this.clubInfo.hash
      }
      // this.event.init_date = new Date(this.event.init_date)
      // this.event.end_date = new Date(this.event.end_date)

      // * If event has id => Edit Event
      // Emit event for add/update event
      if (eventData.hash) {
        this.$emit('update-event', this.event)
      } else {
        this.$emit('add-event', this.event)
      }

      // Close sidebar
      this.$emit('is-event-handler-sidebar-active')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

// @media (max-width: 575.98px) {
//   .sidebar-lg {
//     width: 20rem;
//     justify-content: center !important;
//     align-items: center !important;
//     display: flex !important;
//     margin-left: auto !important;
//     margin-right: auto !important;
//   }
// }
.b-sidebar {
  margin: auto !important;
  // Sidebar Width
  &.sidebar-lg {
    width: 30rem;
  }
}
.padding-date-time {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 13px;
}
@media (max-width: 400px) {
  .b-sidebar .b-sidebar-right {
    left: 0 !important;
    right: 0 !important;
  }
  .sidebar-lg {
      width: 20rem;
      justify-content: center !important;
      align-items: center !important;
      display: flex !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
}
</style>
