<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25 d-md-inline-flex d-none"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotfications"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('notifications') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ unreadNotfications }} {{ $t('new-notifications') }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Events Notification -->
      <b-link
        v-for="event in notifications"
        :key="event.hash"
        @click.stop="goNotification(event)"
      >
        <b-media :class="{'bg-notifications': !event.is_read}">
          <template #aside>
            <b-avatar
              size="35"
              :variant="event.notification_type.name == 'EVENT' ? 'light-primary' : 'light-info'"
            >
              <i :class="event.notification_type.name == 'EVENT' ? 'icon-0-icons-dark-calendar' : 'icon-0-icons-dark-pin'" />
            </b-avatar>
          </template>
          <p
            class="media-heading"
          >
            <span :class="[event.is_read ? 'font-weight-normal' : 'font-weight-bolder']">
              {{ event.user.full_name }} {{ event.action.labelables[0].label }}:
            </span>
          </p>
          <small
            v-if="event.title"
            class="notification-text"
          >{{ event.title.length > 55 ? event.title.substring(0,55)+"..." : event.title }}</small><br>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :style="colorPrimaryBtn"
        block
        @click="handleMarkRead"
      >{{ $t('mark-all-as-read') }}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { formatDateToMonthShort } from '@core/utils/filter'
import store from '@/store'
import checkPermission from '@/auth/permissions'

const soundNotification = { soundurl: '/assets/sound/relax-message-tone.mp3' }

const baseUrl = window.location.origin

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showDetails: [],
      title: null,
      description: null,
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'notificationStore/notifications',
      unreadNotfications: 'notificationStore/unreadNotfications',
      colorPrimaryBtn: 'colorPrimaryBtn',
      idUserAuth: 'id',
      clubInfo: 'clubInfo',
    }),
  },
  mounted() {
    const userId = this.idUserAuth / 65389
    let clubKey = 'OSTRAIL'
    if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      clubKey = this.clubInfo.hash / 65389
    }
    setTimeout(() => {
      window.Echo.private(`notification.user.${userId}.club.${clubKey}`).listen('NotificationSent', e => {
        this.handleNotification(e.message)
        this.fetchCounterNotifications()
        this.playSound()
      })
    }, 12000)
  },
  methods: {
    checkPermission,
    formatDateToMonthShort,
    ...mapActions({
      fetchNotifications: 'notificationStore/fetchData',
      updateStatusNotification: 'notificationStore/updateStatus',
      fetchCounterNotifications: 'notificationStore/fetchCounterNotifications',
      fetchNotificationById: 'notificationStore/fetchNotificationById',
      markAllAsRead: 'notificationStore/markAllAsRead',
    }),
    async handleNotification(message) {
      await this.fetchNotificationById(message).then(response => {
        const resp = response.data.data

        this.title = `${resp.user.full_name} ${resp.action.labelables[0].label}:`
        this.description = resp.title

        this.showNotification(this.title, this.description)

        this.title = null
        this.description = null
      })
      await this.fetchNotifications()
    },
    async goNotification(event) {
      if (event.notification_type.name === 'EVENT') {
        store.commit('calendar/SET_IS_CALENDAR', true)
      }
      if (event.notification_type.name === 'TODO' && this.$route.name !== 'todo') {
        this.$router.push({ name: 'todo' })
      }
      await this.updateStatusNotification(event.hash).then(async () => {
        await this.fetchNotifications()
        await this.fetchCounterNotifications()
      })
    },
    async playSound() {
      const audio = new Audio(baseUrl + soundNotification.soundurl)
      await audio.play()
    },
    async handleMarkRead() {
      await this.markAllAsRead()
      await this.fetchNotifications()
      await this.fetchCounterNotifications()
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

.bg-notifications {
  background-color: #f1f1f1;
}

</style>
