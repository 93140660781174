<template>
  <div>
    <b-row v-if="isLoadingOstrail">
      <b-col>
        <overlay-content
          class="mt-5"
          :loading="isLoadingOstrail"
        />
      </b-col>
    </b-row>
    <b-row v-if="noticesOstrail.length > 0">
      <b-col cols="12">
        <div class="p-1">
          <h5>
            <feather-icon
              icon="StarIcon"
              size="13"
              class="cursor-pointer text-warning"
              style="margin-top: -5px"
            /> {{ $t('generic.newsOstrail') }}
          </h5>
          <b-carousel
            id="carousel-crossfade"
            ref="carouselOstrail"
            v-model="slide"
            controls
            indicators
            fade
            :interval="5000"
            img-width="371"
            img-height="247"
          >
            <b-carousel-slide
              v-for="item in noticesOstrail"
              :key="item.headline"
            >
              <template #img>
                <div class="objetfit">
                  <img
                    class="d-block img-fluid w-100 i-margin-img-slide"
                    width="371"
                    height="247"
                    :src="item.image.url_absolute"
                    alt="image OStrail"
                  >
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel></div>
      </b-col>
      <b-col cols="12">
        <div class="p-1">
          <h5
            class="cursor-pointer"
            @click="handleNewsView(noticesOstrail[slide])"
          >
            <b-link>
              {{ noticesOstrail[slide].title }}

            </b-link>
          </h5>
          <!--
          <b-link
            @click="handleNewsView(noticesOstrail[slide])"
          >
            <small class="text-muted">{{ $t('Details') }}</small>
          </b-link>
          -->
          <small class="text-muted"> {{ formatDate(noticesOstrail[slide].init_publication_date) }}</small>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row v-if="isLoadingESPN">
      <b-col>
        <overlay-content
          class="mt-5"
          :loading="isLoadingESPN"
        />
      </b-col>
    </b-row>

    <!-- Noticias Espn -->
    <b-row v-if="newListAPIESPN.length > 0">
      <b-col cols="12">
        <div class="p-1">
          <h5>
            <feather-icon
              icon="StarIcon"
              size="13"
              class="cursor-pointer text-warning"
              style="margin-top: -5px"
            /> {{ $t('generic.news') }} <b-link
              target="_blank"
              href="https://www.espn.com/"
            >
              ESPN
            </b-link>
          </h5>
          <b-carousel
            id="carousel-crossfade"
            ref="carouselESPN"
            v-model="slideApi"
            controls
            indicators
            fade
            :interval="5000"
            img-width="608"
            img-height="342"
          >
            <b-carousel-slide
              v-for="item in newListAPIESPN"
              :key="item.headline"
            >
              <template #img>
                <img
                  class="d-block img-fluid w-100 i-margin-img-slide"
                  width="608"
                  height="342"
                  :src=" item.images.length > 0 ? item.images[0].url : ''"
                  alt="image ESPN"
                >
              </template>
            </b-carousel-slide>
          </b-carousel></div>
      </b-col>
      <b-col
        class="mb-5"
        cols="12"
      >
        <div
          v-if="newListAPIESPN.length > 0"
          class="p-1"
        >
          <h5>
            <b-link
              target="_blank"
              :href="newListAPIESPN[slideApi].links.web.href"
            >
              {{ newListAPIESPN[slideApi].headline }}
            </b-link>
          </h5>
          <!--
          <b-link
            target="_blank"
            :href="newListAPIESPN[slideApi].links.web.href"
          >
            <small class="text-muted"> {{ newListAPIESPN[slideApi].links.web.href }}</small>
          </b-link><br>
            <small class="text-muted"> <b>{{ newListAPIESPN[slideApi].byline }}</b> </small>
          -->
          <small class="text-muted"> {{ formatDate(newListAPIESPN[slideApi].published) }}</small>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate } from '@/@core/utils/filter'
import OverlayContent from '@/views/partials/OverlayContent.vue'

export default {
  components: {
    OverlayContent,
  },
  data() {
    return {
      newListAPIESPN: [],
      perPage: 1,
      slide: 0,
      slideApi: 0,
      isLoadingOstrail: false,
      isLoadingESPN: false,
      noticesOstrail: [],
    }
  },
  watch: {
    '$i18n.locale': function i18nlocale() {
      this.fetchDataApiEspn()
      this.fetchDataApiOStrail()
    },
  },
  created() {
    this.fetchDataApiEspn()
    this.fetchDataApiOStrail()
  },
  methods: {
    formatDate,
    ...mapActions({
      // fetchNewsSidebar: 'news/fetchNewsSidebar',
      fetchDataSidebarNotices: 'noticeStore/fetchDataSidebar',
    }),
    async fetchDataApiEspn() {
      this.newListAPIESPN = []
      this.isLoadingESPN = true
      let url = 'es'
      if (this.$i18n.locale === 'es') {
        url = 'https://site.api.espn.com/apis/site/v2/sports/soccer/mex.1/news?lang=es'
      } else {
        url = 'https://site.api.espn.com/apis/site/v2/sports/soccer/usa.1/news?lang=en'
      }
      const data = await fetch(url)
        .then(response => response.json())
        .catch(error => {
          this.responseCatch(error)
        })
      this.newListAPIESPN = data.articles
      this.isLoadingESPN = false
    },
    async fetchDataApiOStrail() {
      this.isLoadingOstrail = true
      await this.fetchDataSidebarNotices({ filtersObj: { owned_by: 'OSTRAIL', location: this.$i18n.locale } }).then(response => {
        this.noticesOstrail = response.data.data
        this.isLoadingOstrail = false
      }).catch(error => {
        this.isLoadingOstrail = false
        this.responseCatch(error)
      })
    },
    handleNewsView(item) {
      this.$emit('hidden-news', false)
      this.$router.replace({ name: 'new-details', params: { id: item.slug } })
    },
  },
}
</script>

<style scoped>
.i-margin-img-slide {
  border-radius: 8px;
}

.objetfit > img{
  min-width: 200px;
  height: 200px;
  object-fit: cover;
  background-color: black;
}
</style>
