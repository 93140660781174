/* eslint-disable */
// eslint-disable-next-line no-unused-vars
import checkPermission from '@/auth/permissions'

// Array of sections
export default [
  {
    title: 'routes.documentalCollectionRoute.documentalCollection',
    route: 'clubs',
    icon: 'icon-0-icons-dark-folder i-font-size',
    key: 'DOCCE',
    sections: [
      { route: { name: 'clubs' } },
      { route: { name: 'create-club' } },
      { route: { name: 'edit-club' } },
      { route: { name: 'show-club' } },
      { route: { name: 'admin-create-club' } },
      { route: { name: 'corporate' } },
      { route: { name: 'society' } },
      { route: { name: 'representative' } },
      { route: { name: 'power-of-attorney' } },
      { route: { name: 'assemblies' } },
      { route: { name: 'intellectual-property' } },
      { route: { name: 'pi-agreements' } },
      { route: { name: 'pi-trademarks' } },
      { route: { name: 'pi-literary-or-artistic-works' } },
      { route: { name: 'member' } },
      { route: { name: 'create-member' } },
      { route: { name: 'details-member' } },
      { route: { name: 'edit-member' } },
      { route: { name: 'team' } },
    ],
  },
  {
    title: 'routes.sportsIntelligenceRoute.sportsIntelligence',
    route: 'sports-intelligence',
    icon: 'icon-0-icons-dark-presentation i-font-size',
    key: 'SPINA',
    sections: [],
  },
  {
    title: 'routes.legalRoute.legal',
    route: 'legal',
    icon: 'icon-0-icons-dark-legal i-font-size',
    key: 'LEGLE',
    sections: [],
  },
  {
    title: 'routes.competitionRoute.competition',
    route: 'competition',
    icon: 'icon-0-icons-dark-cup i-font-size',
    key: 'COMNA',
    sections: [],
  },
  {
    title: 'routes.financialRoute.financial',
    route: 'financial',
    icon: 'icon-0-icons-dark-world i-font-size',
    key: 'FICLU',
    sections: [],
  },
  {
    title: 'routes.medicineRoute.medicine',
    route: 'medicine',
    icon: 'icon-0-icons-dark-doctor i-font-size',
    key: 'MEDNO',
    sections: [],
  },
  {
    title: 'routes.inventoryRoute.inventory',
    route: 'inventory',
    icon: 'icon-0-icons-dark-list i-font-size',
    key: 'IVTYA',
    sections: [],
  },
  {
    title: 'routes.commercialRoute.commercial',
    route: 'commercial',
    icon: 'icon-fill-200 i-font-size',
    key: 'CMALU',
    sections: [],
  },
  {
    title: 'routes.warehouseRoute.warehouse',
    route: 'warehouse',
    icon: 'icon-0-icons-dark-cart i-font-size',
    key: 'WRHSO',
    sections: [],
  },
  {
    title: 'routes.infrastructureRoute.infrastructure',
    route: 'infrastructure',
    icon: 'icon-0-icons-dark-stadium i-font-size',
    key: 'IFTRA',
    sections: [],
  },
  {
    title: 'routes.administrationRoute.administration',
    route: 'roles',
      //   checkPermission(['VIEW_ROLES'])
      // ? 'roles' : checkPermission(['VIEW_USERS'])
      //   ? 'users' : checkPermission(['VIEW_JOBS'])
      //     ? 'job-position' : checkPermission(['VIEW_NOTICES'])
      //       ? 'news' : checkPermission(['VIEW_TASK'])
      //         ? 'todo' : checkPermission(['VIEW_LOGS_INFO'])
      //           ? 'logs-info' : 'not-authorized',
    icon: 'icon-0-icons-dark-settings i-font-size',
    key: 'ADMNA',
    sections: [
      { route: { name: 'roles' } },
      { route: { name: 'users' } },
      { route: { name: 'permissions' } },
      { route: { name: 'job-position' } },
      { route: { name: 'users-permissions' } },
      { route: { name: 'news' } },
      { route: { name: 'new-edit' } },
      { route: { name: 'new-add' } },
      { route: { name: 'new-details' } },
      { route: { name: 'new-posts' } },
      { route: { name: 'public-news' } },
      { route: { name: 'todo' } },
      { route: { name: 'todo-filter' } },
      { route: { name: 'todo-tag' } },
      { route: { name: 'logs-info' } },
    ],
  },
]
