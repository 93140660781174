<template>
  <div class="i-show-menu-movil">
    <div class="i-modal-container">
      <div class="i-menu-modal i-translate-modal">
        <div class="i-modal-header">
          <div class="i-current-module">
            <h2 class="text-white pl-1">
              <!-- <neutral-horizontal-ostrail-logo v-if="checkPermission(['ALLOW_ALL'])" /> -->
              <div @click="goDashboard">
                <b-avatar
                  v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                  class="i-avatar-cl cursor-pointer"
                  rounded="sm"
                  size="39px"
                  src="@/assets/images/ostrail/verticalLogo.png"
                />
                <b-avatar
                  v-else
                  class="i-avatar-cl cursor-pointer"
                  rounded="sm"
                  size="39px"
                  :src="clubInfo.club_image_file_hash == null ? null : path + clubInfo.club_image_media_file.url_relative"
                />
              </div>
            </h2>
            <h4 class="text-white pl-1">
              {{ $t(currentOption) }}
            </h4>
          </div>
        </div>
        <div class="i-modal-body">
          <div class="row">
            <div
              v-for="optionA in filteredNavMenuItems"
              :key="optionA.icon"
              class="col-6 col-sm-4 my-2 i-padding-icons"
              @click="goRoute(optionA)"
            >
              <h2 class="text-white">
                <i :class="optionA.icon" />
              </h2>
              <h5 class="text-white i-padding-icons">
                <p>{{ $t(optionA.title) }}</p>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-nested-ternary */
import navMenuItems from '@/navigation/vertical'
import checkPermission from '@/auth/permissions'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      navMenuItems,
      currentOption: '',
      currentIcon: '',
    }
  },
  computed: {
    ...mapGetters({
      clubInfo: 'clubInfo',
      role: 'role',
      path: 'path',
    }),

    filteredNavMenuItems() {
      let itemAux = JSON.parse(JSON.stringify(this.navMenuItems))

      for (let j = 0; j < itemAux.length; j++) {
        if (itemAux[j].key == 'ADMNA') {
          itemAux[j].route = this.checkPermission(['VIEW_ROLES'])
            ? 'roles' : this.checkPermission(['VIEW_USERS'])
              ? 'users' : this.checkPermission(['VIEW_JOBS'])
                ? 'job-position' : this.checkPermission(['VIEW_NOTICES'])
                  ? 'news' : this.checkPermission(['VIEW_TASK'])
                    ? 'todo' : this.checkPermission(['VIEW_LOGS_INFO'])
                      ? 'logs-info' : 'not-authorized'
        } else if (itemAux[j].key == 'DOCCE') {
          itemAux[j].route = this.checkPermission(['VIEW_CLUB'])
            ? 'clubs' : this.checkPermission(['VIEW_MEMBERS_PLAYERS', 'VIEW_MEMBERS_COACHING_STAFF'])
              ? 'member' : this.checkPermission(['VIEW_TEAMS']) // falta cuerpo tecnico
                ? 'team' : 'not-authorized'
        }
      }
      return itemAux.filter(it => checkPermission([it.key]))
    },
  },
  mounted() {
    let itemX = null
    document.body.style.overflow = 'hidden'

    navMenuItems.forEach(it => {
      it.sections.forEach(r => {
        if (r.route.name === this.$route.name) {
          itemX = it
        }
      })
    })

    this.currentOption = this.$route.name == 'dashboard-main' ? 'generic.dashboard' : itemX.title
    this.currentIcon = this.$route.name == 'dashboard-main' ? 'icon-0-icons-dark-house-copy' : itemX.icon
    this.$emit('assign-icon', this.currentIcon)
  },
  destroyed() {
    document.body.style.overflow = 'visible'
  },
  methods: {
    checkPermission,
    goRoute(option) {
      this.currentOption = option.title
      this.currentIcon = option.icon
      this.$emit('assign-icon', option.icon)
      this.$emit('handle-show-menu', false)
      this.$router.push({ name: option.route })
    },
    goDashboard() {
      this.currentOption = 'generic.dashboard'
      this.currentIcon = 'icon-0-icons-dark-house-copy'
      this.$emit('assign-icon', 'icon-0-icons-dark-house-copy i-padding-top-icon-d')
      this.$emit('handle-show-menu', false)
      this.$router.push({ name: 'dashboard-main' })
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .i-show-menu-movil {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.i-modal-container {
  width: 100%;
  min-height: 100vh;
  position:fixed;
  z-index: 1000;
  top: 0;
  right:0;
  bottom:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  background-color: rgba(51, 51, 77, 0.3);
}

.i-menu-modal {
  width: 85%;
  height: 80%;
  background-color: rgb(51, 51, 77);
  border-radius: 10px;
  margin-top: -51px;
}

.i-modal-header {
  top: 0;
  height: 15%;
  position: sticky;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  padding: 20px;
}

.i-modal-body {
  padding: 0 15px;
  overflow: auto;
  width: 100%;
  height: 82%;
  text-align: center;
}

.i-current-module {
  height: 27px;
  display: flex;
  align-items: center;
  border-left: solid 2px #80ff95;
}

.i-padding-icons {
  padding: 2px;
  cursor: pointer;
}

.i-translate-modal {
  animation: i-move 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes i-move-modal {
  from {
    transform: translateY(-300px);
  }

  to {
    transform: translateY(0px);
  }
}

.i-avatar-cl {
  background-color: transparent;
  margin-left: 0px;
  margin-top: 1px !important;
}
</style>
