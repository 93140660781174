<template>
  <!-- Body -->
  <div class="i-margin-messages">
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <div class="chats i-margin-scroll">
        <b-form-checkbox
          v-if="showTrashButton"
          :value="!applyAll"
          class="custom-control-primary i-mg-check-list"
          @input="assignAll(!applyAll)"
        >
          {{ $t('generic.selectAll') }}
        </b-form-checkbox>
        <hr v-if="showTrashButton">
        <div
          v-for="(msg, index) in messageList.messages"
          :key="msg.hash+String(index)"
          class="chat"
          :class="{'chat-left': msg.user_hash !== authUserId}"
        >
          <div
            v-if="showTrashButton"
            class="float-left"
          >
            <b-form-checkbox
              v-model="msg.selected"
              class="custom-control-primary i-mg-check-list"
            />
          </div>
          <div class="chat-avatar">
            <b-avatar
              v-if="msg.user.avatar_file_hash != 0 && msg.user.avatar_file_hash != null"
              size="36"
              class="avatar-border-2 box-shadow-1"
              variant="transparent"
              :src="path + msg.user.avatar_media_file.url_relative"
            />
            <b-avatar
              v-else
              variant="secondary"
              :text="msg.user.initials"
            />
          </div>
          <div class="chat-body">
            <div class="chat-content i-text-content cursor-pointer">
              <p>{{ msg.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </vue-perfect-scrollbar>
    <!-- Message Input -->
    <validation-observer
      ref="messageForm"
      v-slot="{ handleSubmit, reset }"
    >
      <b-form
        class="chat-app-form"
        @submit.prevent="handleSubmit(handleReplyMessage)"
        @reset.prevent="reset"
      >
        <validation-provider
          #default="{ errors }"
          rules="required|max:255"
          name="message"
        >
          <b-input-group class="input-group-merge form-send-message mr-1 pb-0 i-box-s-none">
            <b-form-input
              v-model="messageForm.message_content"
              :placeholder="$t('reply')"
            />
            <b-input-group-append>
              <b-button
                type="submit"
                variant="outline-primary"
              >
                <!-- Enviar -->
                <feather-icon icon="SendIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger i-pd-left-error">{{ errors[0] }}</small>
        </validation-provider>
      </b-form>
    </validation-observer>
  </div>
  <!-- </b-modal> -->
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import messageService from '@/services/messageService'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: String,
      required: true,
    },
    messageList: {
      type: Object,
      required: true,
    },
    conversationDetail: {
      type: Object,
      required: true,
    },
    authUserId: {
      type: Number,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    showTrashButton: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      applyAll: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      messageForm: {
        message_content: '',
        user_id: null,
      },
    }
  },
  watch: {
    showTrashButton(currentValue) {
      if (currentValue) {
        this.assignAll(false)
      }
    },
  },
  methods: {
    assignAll(value) {
      this.applyAll = value

      for (let i = 0; i <= this.messageList.messages.length - 1; i++) {
        this.messageList.messages[i].selected = value
      }
    },
    handleReplyMessage() {
      this.messageForm.user_id = this.authUserId

      messageService.setMessage(this.conversationDetail.hash, this.messageForm).then(({ data }) => {
        this.$refs.messageForm.reset()
        this.$emit('on-add-message', this.messageForm)
        this.resetForm()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    handleDeleteComment(commentData) {
      this.$emit('on-delete-message', commentData)
      this.$refs.messageForm.reset()
    },
    resetForm() {
      this.messageForm.message_content = ''
      this.messageForm.user_id = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
.scroll-area {
  position: relative;
  margin: auto;
  width: 99%;
  max-height: 300px;
  height: auto;
  padding: 0 2px;
}
.i-margin-messages {
  margin: 4px 0px;
}
.i-margin-scroll {
  margin: 0 12px 0 4px;
}
.chats {
  .chat-avatar {
    float: right;
  }
  .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden;
    .chat-content {
      float: right;
      padding: 0.4rem 0.8rem;
      margin: 0 10px 8px 0;
      clear: both;
      color: $body-color;
      background-color: #f6f8fb;
      border-radius: $border-radius;
      box-shadow: 0 1px 6px 0 rgba($black, 0.18);
      max-width: 75%;
      p {
        margin: 0;
      }
    }
    .btn.btn-icon {
      float: right !important;
      margin-right: 7px;
      padding: 0.315rem 0.136rem;
    }
  }
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
      .chat-content {
        float: left;
        margin: 0 0 8px 10px;
        color: #fff;
        background: none;
        background-color: #6abeff;
      }
      .btn.btn-icon {
        float: left !important;
        margin-left: 5px;
        padding: 0.315rem 0.136rem;
      }
    }
  }
}
.i-text-content {
  float: right;
  display: flex;
  align-items: center;
  word-break:break-all;
}
.i-box-s-none {
  box-shadow: none !important;
}
.i-pd-left-error {
  padding-left: 15px;
}
.i-mg-check-list {
  margin-top: 8px;
}
</style>
