<template>
  <b-dropdown
    id="dropdown-grouped"
    v-ripple.400="'rgba(255, 255, 255, 0.4)'"
    class="d-md-inline-flex d-none"
    variant="link"
    right
  >
    <template #button-content>
      <b-avatar
        class="i-avatar-cl-filter"
        rounded="sm"
        size="17px"
        :src="clubImage"
      />
      <span class="ml-50 text-body">{{ club }}</span>
    </template>
    <b-dropdown-item
      v-for="item in sportClubs"
      :key="item.id"
      @click="handleFiltersClub(item, true)"
    >
      <b-avatar
        class="i-avatar-cl-filter"
        rounded="sm"
        size="17px"
        :src="item.club_image_file_hash == null || item.club_image_file_hash == 0 ? null : path + item.club_image_media_file.url_relative"
      />
      <span class="ml-50">{{ item.club_name }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import clubService from '@/services/clubsService'
import permissionService from '@/services/permissionService'
import userService from '@/services/userService'
import store from '@/store'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      club: null,
      clubId: null,
      auxClub: null,
      clubImage: null,
      userInfo: null,
      filters: {
        page: 1,
        perPage: 10,
        idClub: null,
        idMemberType: 'player',
      },
    }
  },
  computed: {
    ...mapGetters({
      role: 'role',
      currentClub: 'clubInfo',
      sportClubs: 'sport_clubs',
      selectType: 'memberStore/selectType',
      path: 'path',
    }),
  },
  watch: {
    currentClub() {
      this.setColorValueSidebar(this.currentClub.background_color)
      this.setColorValue(this.currentClub.btn_color)
    },
  },
  mounted() {
    if (this.sportClubs.length) {
      this.setColorValueSidebar(this.currentClub.background_color)
      this.setColorValue(this.currentClub.btn_color)
      const dataClub = this.currentClub
      this.clubId = dataClub.hash
      this.club = dataClub.club_name
      this.clubImage = dataClub.club_image_file_hash == null ? null : this.path + dataClub.club_image_media_file.url_relative
      this.auxClub = dataClub.club_name
      this.filters.idClub = this.clubId
      this.handleFiltersClub(dataClub, false)
    }
    // this.fetchNotifications()
  },
  methods: {
    ...mapActions({
      getRolesData: 'roleStore/getRolesData',
      getUsersData: 'userStore/getUsersData',
      getJobsData: 'jobPosition/getJobsData',
      getMembersData: 'memberStore/getMembers',
      getTeamsData: 'teamStore/getTeamsData',
      fetchTeamByIdClub: 'teamStore/fetchTeamByIdClub',
      // fetchNotifications: 'notificationStore/fetchData',
      setColorValueSidebar: 'setColorValueSidebar',
      setColorValue: 'setColorValue',
      // fetchNews: 'news/fetchNews',
      fetchMessages: 'message/fetchMessages',
    }),
    handleFiltersClub(club, fl) {
      // this.fetchNotifications()
      if (fl && this.auxClub !== club.club_name) {
        this.switchClub(club)
        // this.filters.idClub = club.id
        // this.fetchTeamByIdClub(this.filters.idClub)
        // this.getRolesData(this.filters)
        // this.getUsersData(this.filters)
        // this.getJobsData(this.filters)
        // this.getTeamsData(this.filters)

        // if (this.selectType) {
        //   this.filters.idMemberType = this.selectType
        // }

        // this.fetchEventNotifications()
        // this.getMembersData(this.filters)
        // this.fetchNews(this.filters)
        // this.fetchMessages()
      }
    },

    switchClub(pClub) {
      clubService.switchCurrentClub(this.currentClub.hash, pClub.hash).then(({ data }) => {
        this.getUserInfo()

        this.club = pClub.club_name
        this.clubImage = pClub.club_image_file_hash == null || pClub.club_image_file_hash == 0 ? null : this.path + pClub.club_image_media_file.url_relative

        setTimeout(() => {
          this.auxClub = pClub.club_name

          if (this.$route.name != 'dashboard-main') {
            this.$router.push({ name: 'dashboard-main' })
          }
        }, 100)
      })
    },

    getUserInfo() {
      const data = null

      userService.getUserAuthenticated(data).then(response => {
        this.userInfo = response.data.data
        this.getPermissions()
      }).catch(error => {
        console.error(error)
      })
    },

    getPermissions() {
      permissionService.getPermissionsByUser().then(response => {
        const permissionsList = response.data.data

        if (this.userInfo.clubs.length) {
          const sportClub = this.userInfo.clubs.filter(el => el.is_current)
          store.dispatch('setCurrentClub', sportClub[0])
        }

        store.dispatch('setInfo', { user: this.userInfo, permissions: permissionsList })
      }).catch(error => {
        console.error(error.message)
      })
    },
  },
}
</script>

<style scoped>
.i-avatar-cl-filter {
  background-color: transparent;
}
</style>
