<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header (sidebar) -->
    <div
      id="section-one"
      class="navbar-header expanded"
    >
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link :to="checkPermission(['VIEW_DASHBOARD']) ? { name: 'dashboard-main' } : { name:'not-authorized' }">
              <div
                v-if="isVerticalMenuCollapsed && !isMouseHovered"
                class="row"
              >
                <vertical-ostrail-logo v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])" />
                <b-avatar
                  v-else
                  class="mt-1 i-avatar-cl"
                  rounded="sm"
                  size="39px"
                  :src="clubInfo.club_image_file_hash == null ? null : path + clubInfo.club_image_media_file.url_relative"
                />
              </div>
              <template v-else>
                <div
                  v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                  class="row ml-2"
                >
                  <horizontal-ostrail-logo />
                </div>
                <div
                  v-else
                  class="row"
                >
                  <b-avatar
                    class="mt-1 i-avatar-cl"
                    rounded="sm"
                    size="39px"
                    :src="clubInfo.club_image_file_hash == null ? null : path + clubInfo.club_image_media_file.url_relative"
                  />
                  <p
                    class="brand-text text-white ellipsis i-text-cl-name-md"
                    :class="{'i-text-cl-name-lg' : clubInfo.club_name.length > 20}"
                  >
                    {{ clubInfo.club_name }}
                  </p>
                </div>
              </template>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle text-white">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header (sidebar) -->

    <!-- main menu content (sidebar) -->
    <vue-perfect-scrollbar
      id="section-two"
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area pt-1"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        id="section-three"
        :items="filteredNavMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content (sidebar) -->
  </div>
</template>

<script>
/* eslint-disable no-nested-ternary */
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import { mapGetters } from 'vuex'
import { BLink, BAvatar } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'

import checkPermission from '@/auth/permissions'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'

import HorizontalOstrailLogo from '@/views/partials/HorizontalOstrailLogo.vue'
import VerticalOstrailLogo from '@/views/partials/VerticalOstrailLogo.vue'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    HorizontalOstrailLogo,
    VerticalOstrailLogo,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BAvatar,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      clubInfo: 'clubInfo',
      path: 'path',
      id: 'id',
    }),

    filteredNavMenuItems() {
      let itemAux = JSON.parse(JSON.stringify(this.navMenuItems))

      for (let i = 0; i < itemAux.length; i++) {
        if (itemAux[i].key == 'ADMNA') {
          itemAux[i].route = this.checkPermission(['VIEW_ROLES'])
            ? 'roles' : this.checkPermission(['VIEW_USERS'])
              ? 'users' : this.checkPermission(['VIEW_JOBS'])
                ? 'job-position' : this.checkPermission(['VIEW_NOTICES'])
                  ? 'news' : this.checkPermission(['VIEW_TASK'])
                    ? 'todo' : this.checkPermission(['VIEW_LOGS_INFO'])
                      ? 'logs-info' : 'not-authorized'
        } else if (itemAux[i].key == 'DOCCE') {
          itemAux[i].route = this.checkPermission(['VIEW_CLUB'])
            ? 'clubs' : this.checkPermission(['VIEW_MEMBERS_PLAYERS', 'VIEW_MEMBERS_COACHING_STAFF'])
              ? 'member' : this.checkPermission(['VIEW_TEAMS']) // falta cuerpo tecnico
                ? 'team' : 'not-authorized'
        }
      }
      return itemAux.filter(it => checkPermission([it.key]))
    },
  },

  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      checkPermission,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.ellipsis {
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  width: 145px;
}

.i-text-cl-name-md {
  margin-left: 6px;
  margin-top: 23px;
  font-size: 15px;
}

.i-text-cl-name-lg {
  margin-left: 6px;
  margin-top: 17px;
  font-size: 15px;
}

.i-avatar-cl {
  background-color: transparent;
  padding: 1px;
  margin-left: 14px;
}
</style>
