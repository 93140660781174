<template>
  <div style="height: inherit">
    <b-nav-item-dropdown
      class="dropdown-notification mr-25 d-md-inline-flex d-none"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="counterNotifications > 0 ? counterNotifications: null"
          badge-classes="bg-danger"
          class="text-body"
          icon="MessageSquareIcon"
          size="21"
        />
      </template>
      <!-- Message -->
      <message
        :message-number="currentMessages.length"
        @assign-counter-value="assignCounterValue"
      />
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Message from '../../app-message/Message.vue'

const soundNotification = { soundurl: '/assets/sound/relax-message-tone.mp3' }
const baseUrl = window.location.origin

export default {
  components: {
    Message,
  },
  data() {
    return {
      currentMessages: [],
      counterNotifications: 0,
    }
  },
  computed: {
    ...mapGetters({
      idUserAuth: 'id',
    }),
  },
  mounted() {
    setTimeout(() => {
      const userId = this.idUserAuth / 65389
      window.Echo.private(`conversation.user.${userId}`).listen('MessageSent', e => {
        this.currentMessages.push(e.message)
        this.playSound()
      })
    }, 12000)
  },
  methods: {
    assignCounterValue(pValue) {
      this.counterNotifications = pValue
    },
    async playSound() {
      const audio = new Audio(baseUrl + soundNotification.soundurl)
      await audio.play()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-email.scss";
.media {
  position: relative;
  &:hover {
    .message-button-reply {
      visibility: visible;
    }
  }
}
</style>
