import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getConversations(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`conversations${query}`)
  },

  async getMessagesByConversation(id) {
    return await apiInstance.get(`conversations/${id}/messages`)
  },

  async setNewConversation(data) {
    return await apiInstance.post('conversations/new', data)
  },

  async setMessage(id, data) {
    return await apiInstance.post(`conversations/${id}/messages`, data)
  },

  async deleteConversation(id) {
    return await apiInstance.delete(`conversations/${id}`)
  },

  async deleteMessages(id, data) {
    return await apiInstance.post(`conversations/${id}/messages/trash`, data)
  },
}
