<template>
  <div
    class="customizer d-none d-md-block"
    :class="{'open': isCustomizerOpen || isCalendars || isNews}"
  >
    <!-- toggler-->
    <!-- <b-link
      class="i-toggle-default i-customizer-toggle d-flex align-items-center justify-content-center"
      :class="{'i-color-toggle-disabled' : (isCalendars || isNews)}"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        icon="SettingsIcon"
        size="15"
        class="spinner"
      />
    </b-link> -->
    <b-link
      v-if="checkPermission(['ALLOW_ALL', 'VIEW_EVENTS'])"
      class="i-toggle-default i-calendar-toggle d-flex align-items-center justify-content-center"
      @click="showCalendar(!isCalendars)"
    >
      <span
        class="i-toggle-default-span i-calendar-toggle d-flex align-items-center justify-content-center"
        :class="{'i-color-toggle-disabled' : (isCustomizerOpen || isNews) || (!isCustomizerOpen && !isNews && !isCalendars)}"
      >
        <i class="icon-0-icons-dark-calendar" />
      </span>
    </b-link>
    <b-link
      v-if="checkPermission(['ALLOW_ALL', 'VIEW_NOTICES'])"
      class="i-toggle-default i-news-toggle  d-flex align-items-center justify-content-center"
      @click="isNews = !isNews, showCalendar(false)"
    >
      <span
        class="i-toggle-default-span i-news-toggle d-flex align-items-center justify-content-center"
        :class="{'i-color-toggle-disabled' : (isCustomizerOpen || isCalendars) || (!isCustomizerOpen && !isNews && !isCalendars)}"
      >
        <i class="icon-0-icons-dark-newsfeed" />
      </span>
    </b-link>
    <!-- toggler -->

    <!-- header -->
    <div
      v-if="isCustomizerOpen"
      class="customizer-section d-flex justify-content-between align-items-center"
    >
      <div>
        <h4 class="text-uppercase mb-0">
          Theme Customizer
        </h4>
        <small>Customize &amp; Preview in Real Time</small>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>
    <div
      v-else-if="isCalendars"
      class="customizer-section d-flex justify-content-between align-items-center"
    >
      <div>
        <h4 class="text-uppercase mb-0">
          {{ $t('calendar-sport') }}
        </h4>
        <small>{{ $t('journey-events') }}</small>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="showCalendar(!isCalendars)"
      />
      <!-- @click="isCalendars = !isCalendars" -->
    </div>
    <div
      v-else-if="isNews"
      class="customizer-section d-flex justify-content-between align-items-center"
    >
      <div>
        <h4 class="text-uppercase mb-0">
          <b-link
            :to="{ name: 'new-posts'}"
            @click="isNews = !isNews, showCalendar(false)"
          >
            {{ $t('generic.news') }}
          </b-link>
        </h4>
        <small>{{ new Date().toLocaleString() }}</small>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isNews = !isNews"
      />
    </div>
    <!--/ header -->

    <!-- content -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area"
    >
      <template v-if="isCustomizerOpen">
        <!-- Skin, RTL, Router Animation -->
        <div class="customizer-section">

          <!-- Skin -->
          <b-form-group label="Skin">
            <b-form-radio-group
              id="skin-radio-group"
              v-model="skin"
              name="skin"
              :options="skinOptions"
            />
          </b-form-group>

          <!-- Skin -->
          <b-form-group label="Content Width">
            <b-form-radio-group
              id="content-width-radio-group"
              v-model="contentWidth"
              name="content-width"
              :options="contentWidthOptions"
            />
          </b-form-group>

          <!-- RTL -->
          <b-form-group
            label="RTL"
            label-cols="10"
          >
            <b-form-checkbox
              v-model="isRTL"
              class="mr-0 mt-50"
              name="is-rtl"
              switch
              inline
            />
          </b-form-group>

          <!-- Router Transition -->
          <b-form-group
            label="Router Transition"
            label-cols="6"
          >
            <v-select
              v-model="routerTransition"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="title"
              :options="routerTransitionOptions"
              :reduce="option => option.value"
            />
          </b-form-group>
        </div>
        <!-- /Skin, RTL, Router Animation -->

        <!-- SECTION: Menu -->
        <div class="customizer-section">

          <!-- Layout Type -->
          <b-form-group
            label="Menu Layout"
          >
            <b-form-radio-group
              v-model="layoutType"
              name="layout-type"
              :options="layoutTypeOptions"
            />
          </b-form-group>

          <!-- Collapsible -->
          <div
            v-if="layoutType === 'vertical'"
            class="d-flex justify-content-between align-items-center mt-2"
          >
            <span class="font-weight-bold">Menu Collapsed</span>
            <b-form-checkbox
              v-model="isVerticalMenuCollapsed"
              name="is-vertical-menu-collapsed"
              class="mr-0"
              switch
              inline
            />
          </div>

          <!-- Menu Visiblity -->
          <div class="d-flex justify-content-between align-items-center mt-2">
            <span class="font-weight-bold">Menu Hidden</span>
            <b-form-checkbox
              v-model="isNavMenuHidden"
              name="is-menu-visible"
              class="mr-0"
              switch
              inline
            />
          </div>

        </div>

        <!-- SECTION: Navbar -->
        <div class="customizer-section">

          <!-- Navbar Color -->
          <b-form-group
            v-show="layoutType === 'vertical'"
            label="Navbar Color"
          >
            <div
              v-for="(color, index) in navbarColors"
              :key="color"
              class="p-1 d-inline-block rounded mr-1 cursor-pointer"
              :class="[`bg-${color}`, {'border border-light': !index}, {'mark-active': navbarBackgroundColor === color}]"
              @click="navbarBackgroundColor = color"
            />
          </b-form-group>

          <!-- Navbar Type -->
          <b-form-group :label="layoutType === 'vertical' ? 'Navbar Type' : 'Menu Type'">
            <b-form-radio-group
              v-model="navbarType"
              name="navbar-type"
              :options="navbarTypes"
            />
          </b-form-group>
        </div>

        <!-- SECTION: Footer -->
        <div class="customizer-section">

          <!-- Footer Type -->
          <b-form-group label="Footer Type">
            <b-form-radio-group
              v-model="footerType"
              name="footer-type"
              :options="footerTypes"
            />
          </b-form-group>
        </div>
      </template>
      <template v-if="isCalendars && checkPermission(['ALLOW_ALL', 'VIEW_EVENTS'])">
        <!-- <calendar-section /> -->
        <calendar-test />
      </template>
      <template v-else-if="isNews && checkPermission(['ALLOW_ALL', 'VIEW_NOTICES'])">
        <news-section />
      </template>
      <!-- <template>
      </template> -->
    </vue-perfect-scrollbar>
    <!--/ content -->
  </div>
</template>

<script>
import store from '@/store'
// eslint-disable-next-line object-curly-newline
import { BLink, BFormRadioGroup, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import CalendarSection from '@/views/calendars/CalendarSection.vue'
import CalendarTest from '@/views/calendars/CalendarSection.vue'
import NewsSection from '@/views/news/NewsSection.vue'
import checkPermission from '@/auth/permissions'
import useAppCustomizer from './useAppCustomizer'

export default {
  components: {
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,
    vSelect,
    VuePerfectScrollbar,
    // CalendarSection,
    NewsSection,
    CalendarTest,
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Calendars
      isCalendars,

      // News
      isNews,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer()

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 50,
      wheelPropagation: false,
      suppressScrollX: true,
      scrollXMarginOffset: true,
    }

    const showCalendar = value => {
      store.commit('calendar/SET_IS_CALENDAR', value)
    }

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Calendars
      isCalendars,

      // News
      isNews,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Perfect Scrollbar
      perfectScrollbarSettings,
      showCalendar,
      checkPermission,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px);
  margin-right: 5px;
}

.i-color-toggle-disabled {
  border-right: none !important;
  width: 28px;
  height: 18px;
}

.i-toggle-default-span {
  background: transparent !important;
  border-right: solid 2px #80ff95;
  width: 28px;
  height: 18px;
}

.i-toggle-default {
  background: $dark;
  color: $white !important;
  display: block;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: absolute;
  width: 38px;
  height: 38px;
  left: -39px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.i-customizer-toggle {
  top: 44%;
}

.i-calendar-toggle {
  top: 44%;
}

.i-news-toggle {
  top: 51%;
}
</style>
