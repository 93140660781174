<template>
  <!-- Body -->
  <div class="m-1">
    <validation-observer ref="createMessage">
      <b-form-group>
        <label
          for="message-type"
          class="form-label"
        >
          {{ $t('conversations.labels.howDoYouWant') }}
          <span class="text-danger">*</span>
        </label>
        <validation-provider
          #default="{ errors }"
          name="howDoYouWant"
          rules="required"
        >
          <v-select
            id="message-type"
            v-model="message.message_type"
            :state="errors.length > 0 ? false : null"
            label="name"
            :reduce="option => option.id"
            :options="messageTypes"
            :placeholder="$t('conversations.placeholder.selectOption')"
            @input="assignType"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group v-if="message.apply_group">
        <label
          for="group-name"
          class="form-label"
        >
          {{ $t('conversations.labels.groupName') }}
          <span class="text-danger">*</span>
        </label>
        <validation-provider
          #default="{ errors }"
          name="groupName"
          rules="required|max:50|clubNameRegex"
        >
          <b-form-input
            id="group-name"
            v-model="message.group_name"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('conversations.placeholder.enterGroupName')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="message.include_everyone"
          name="check-button"
          switch
          inline
          :checked="message.include_everyone"
          :value="1"
          :unchecked-value="0"
        >
          {{ $t('send-everyone') }}
        </b-form-checkbox>
      </b-form-group>

      <b-form @click.stop.prevent>
        <!-- Field: To -->
        <b-form-group>
          <label
            for="email-to"
            class="form-label"
          >
            {{ $t('send-to') }}:
          </label>
          <validation-provider
            #default="{ errors }"
            name="sendTo"
            :rules="message.include_everyone == 1 ? '' : 'required'"
          >
            <v-select
              v-model="message.guests"
              multiple
              label="name"
              :reduce="option => option.hash"
              class="select-size-sm"
              :close-on-select="false"
              :options="userList"
              input-id="email-to"
              :disabled="message.include_everyone == 1"
              :state="errors.length > 0 ? false : null"
            >
              <template #option="{ avatar_media_file, full_name }">
                <b-avatar
                  size="sm"
                  :src="avatar_media_file == null ? null : path + avatar_media_file.url_relative"
                />
                <span class="ml-50"> {{ full_name }}</span>
              </template>
              <template #selected-option="{ avatar_media_file, full_name }">
                <b-avatar
                  size="sm"
                  class="border border-white"
                  :src="avatar_media_file == null ? null : path + avatar_media_file.url_relative"
                />
                <span class="ml-50"> {{ full_name }}</span>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Exclude Guests -->
        <b-form-group>
          <label
            for="exclude-guests"
            class="form-label"
          >
            {{ $t('exclude') }}:
          </label>
          <v-select
            v-model="message.exclude_guests"
            multiple
            :close-on-select="false"
            :options="userList"
            label="name"
            :reduce="option => option.hash"
            input-id="exclude-guests"
            class="select-size-sm"
            :disabled="message.include_everyone === 0"
          >
            <template #option="{ avatar_media_file, full_name }">
              <b-avatar
                size="sm"
                :src="avatar_media_file == null ? null : path + avatar_media_file.url_relative"
              />
              <span class="ml-50 align-middle"> {{ full_name }}</span>
            </template>
            <template #selected-option="{ avatar_media_file, full_name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar_media_file == null ? null : path + avatar_media_file.url_relative"
              />
              <span class="ml-50 align-middle"> {{ full_name }}</span>
            </template>
          </v-select>
          <label
            v-if="errorInUsers"
            class="text-danger"
          >
            {{ $t(errorInUsers) }}
          </label>
        </b-form-group>

        <!-- Field: Message - Quill Editor -->
        <validation-provider
          #default="{ errors }"
          rules="required|max:255"
          name="message"
        >
          <!-- Textarea -->
          <b-form-group
            :label="$t('generic.message')"
            label-for="event-message"
          >
            <b-form-textarea
              id="event-message"
              v-model="message.content"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- buttons -->
        <div class="float-right mb-1 mt-2">
          <b-button
            variant="secondary"
            pill
            style="margin-left: 17px;"
            @click="cancelMessage"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            pill
            variant="primary"
            style="margin-left: 17px;"
            right
            :style="colorPrimaryBtn"
            @click.prevent="handleAddMessage"
          >
            {{ $t('buttons.send') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
  <!-- </b-modal> -->
</template>

<script>
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import checkPermission from '@/auth/permissions'
import { messageTypes } from '@/services/catalogsService'
import clubService from '@/services/clubsService'
import userService from '@/services/userService'
import messageService from '@/services/messageService'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  model: {
    prop: 'shallShowMessageComposeModal',
    event: 'update:shall-show-message-compose-modal',
  },
  props: {
    shallShowMessageComposeModal: {
      type: String,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userList: [],
      messageTypeList: [],
      errorInUsers: null,
      maxChar: 280,
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'id',
      clubInfo: 'clubInfo',
      path: 'path',
    }),
    messageTypes() {
      const list = this.messageTypeList.map(c => ({ id: c.hash, name: c.name, type: c.type }))
      return list
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.fetchMessageTypes()
    },
    'message.include_everyone': function messageIncludeEveryone(status) {
      this.message.all = null

      if (status === 1) {
        this.message.guests = []
      } else {
        this.message.exclude_guests = []
      }
    },
  },
  mounted() {
    this.fetchMessageTypes()
    this.fetchUsers()
  },
  methods: {
    checkPermission,
    fetchMessageTypes() {
      messageTypes().then(({ data }) => {
        this.messageTypeList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    fetchUsers() {
      if (this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        userService.getAdministratorUsers().then(({ data }) => {
          const userListAux = data.data
          this.userList = userListAux.filter(us => us.hash != this.authUser)
        }).catch(error => {
          this.responseCatch(error)
        })
      } else {
        clubService.getUsersByClubId(this.clubInfo.hash).then(({ data }) => {
          const userListAux = data.data.users
          this.userList = userListAux.filter(us => us.hash != this.authUser)
        }).catch(error => {
          this.responseCatch(error)
        })
      }
    },
    assignType() {
      if (this.message.message_type != null && this.message.message_type != '') {
        const messageT = this.messageTypes.filter(el => el.id == this.message.message_type)
        this.message.apply_group = (messageT[0].type == 'many')
      } else {
        this.message.apply_group = false
      }

      this.message.group_name = ''
    },
    handleAddMessage() {
      this.errorInUsers = null

      if (this.message.include_everyone == 1) {
        this.message.all = this.userList.map(e => e.hash)

        if (this.message.all.length == this.message.exclude_guests.length) {
          this.errorInUsers = 'conversations.labels.cantExclude'
        }
      }

      this.$refs.createMessage.validate().then(success => {
        if (success && this.errorInUsers == null) {
          messageService.setNewConversation(this.message).then(({ data }) => {
            this.$emit('on-add-message')
          })
        }
      })
    },
    cancelMessage() {
      this.$emit('cancel-add-message')
    },
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
// @import '~@core/scss/base/bootstrap-extended/include';

</style>
