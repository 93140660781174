<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Menu Movil -->
    <template v-if="showMenuOstrail">
      <menu-modal
        @assign-icon="assignIcon"
        @handle-show-menu="showMenuOstrail = $event"
      />
    </template>
    <!--/ Menu Movil -->

    <!-- Option Movil -->
    <div
      v-if="!showMenuOstrail"
      class="i-show-menu-button"
    >
      <b-link
        class="i-toggle-option-default d-flex align-items-center justify-content-center"
        @click="showMenuOstrail = true"
      >
        <span class="i-toggle-option-default-span d-flex align-items-center justify-content-center" style="padding-top: 5px">
          <i :class="currentOptionIcon === '' ? initialIcon : currentOptionIcon" />
        </span>
      </b-link>
    </div>
    <!--/ Option Movil -->

    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!--/ Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!--/ Vertical Nav Menu Overlay -->

    <!-- Content Type: Left (view components) -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content Type: Left (view components) -->

    <!-- Footer -->
    <footer
      class="footer footer-light i-show-bottom-navbar"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- / Footer -->

    <!-- News and Calendars  -->
    <slot name="customizer" />
    <!--/ News and Calendars  -->
  </div>
</template>

<script>
import menuItems from '@/navigation/vertical'

import { mapGetters } from 'vuex'
import { ref, onUnmounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { BNavbar, BLink } from 'bootstrap-vue'

import AppFooter from '@core/layouts/components/AppFooter.vue'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import MenuModal from '@/views/partials/MenuModal.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'

import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

export default {
  components: {
    // AppBreadcrumb,
    AppFooter,
    AppNavbarVerticalLayout,
    VerticalNavMenu,

    BNavbar,
    BLink,

    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    MenuModal,
  },

  mixins: [mixinVerticalLayout],

  computed: {
    ...mapGetters({ colorSidebar: 'colorRGBSidebarX' }),

    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },

    initialIcon() {
      const icon = menuItems.filter(it => it.route === this.$route.name)
      // eslint-disable-next-line no-nested-ternary
      return icon.length ? icon[0].icon : this.$route.name == 'dashboard-main' ? 'icon-0-icons-dark-house-copy i-padding-top-icon-d' : 'icon-0-icons-dark-grid i-padding-top-icon'
    },
  },

  watch: {
    '$route.name': function () {
      if (this.$route.name == 'dashboard-main') {
        this.assignIcon('icon-0-icons-dark-house-copy i-padding-top-icon-d')
      } else {
        menuItems.forEach(it => {
          it.sections.forEach(r => {
            if (r.route.name === this.$route.name) {
              this.assignIcon(it.icon)
            }
          })
        })
      }
    },
    colorSidebar() {
      const sOne = document.querySelector('#section-one')
      const sTwo = document.querySelector('#section-two')
      const sThree = document.querySelector('#section-three')

      sOne.style.backgroundColor = this.colorSidebar
      sTwo.style.backgroundColor = this.colorSidebar
      sThree.style.backgroundColor = this.colorSidebar
    },
  },

  mounted() {
    const sOne = document.querySelector('#section-one')
    const sTwo = document.querySelector('#section-two')
    const sThree = document.querySelector('#section-three')

    sOne.style.backgroundColor = this.colorSidebar
    sTwo.style.backgroundColor = this.colorSidebar
    sThree.style.backgroundColor = this.colorSidebar

    if (this.$route.name == 'dashboard-main') {
      this.assignIcon('icon-0-icons-dark-house-copy i-padding-top-icon-d')
    } else {
      menuItems.forEach(it => {
        it.sections.forEach(r => {
          if (r.route.name === this.$route.name) {
            this.assignIcon(it.icon)
          }
        })
      })
    }
  },

  setup() {
    const currentOptionIcon = ref('')
    const showMenuOstrail = ref(false)

    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    const assignIcon = params => {
      currentOptionIcon.value = params
    }

    // Resize handler
    resizeHandler()

    window.addEventListener('resize', resizeHandler)

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      currentOptionIcon,
      assignIcon,
      showMenuOstrail,
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>

<style scoped>
@media screen and (min-width: 768px) {
  .i-show-menu-button {
    display: none;
  }
}

.i-toggle-option-default {
  width: 50px;
  height: 50px;
  display: block;
  background: #33334d;
  color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 70%;
  left: 0;
  position: fixed;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
}

.i-toggle-option-default-span {
  background: transparent !important;
  border-left: solid 2px #80ff95;
  width: 34px;
  height: 28px;
  font-size: 22px;
  padding-left: 8px;
}
.i-padding-top-icon {
  padding-bottom: 4px;
}

.i-padding-top-icon-d {
  padding-bottom: 6px;
}
</style>
