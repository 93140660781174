<template>
  <div>
    <label
      class="cursor-pointer"
      @click="showCalendarModal = true"
    >
      <i class="icon-0-icons-dark-calendar icon-footer-size" />
    </label>

    <b-modal
      v-model="showCalendarModal"
      scrollable
      centered
      hide-footer
      size="notifications"
      :title="$t('generic.eventsCalendar')"
      hide-backdrop
      content-class="shadow"
      @close="$emit('change', false)"
    >
      <vue-perfect-scrollbar
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <calendar-events />
      </vue-perfect-scrollbar>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import CalendarEvents from '@/views/calendars/CalendarSection.vue'

export default {
  components: {
    VuePerfectScrollbar,
    CalendarEvents,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'showCalendarModal',
    event: 'change',
  },
  props: {
    showCalendarModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      // eventsNotifications: 'calendar/eventsNotifications',
    }),
  },
  mounted() {
    // document.body.style.overflow = 'hidden'
  },
  methods: {
    ...mapActions({
      fetchEventNotifications: 'calendar/fetchEventNotifications',
    }),
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';

.scroll-area {
  position: relative;
  margin: auto;
  width: 99%;
  height: 100%;
  max-height: 550px;
}
.b-calendar.calendar-club > .b-calendar-inner {
  width: 270px !important;
  min-width: 100px;
  max-width: 500px;
}

</style>
