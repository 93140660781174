<template>
  <div>
    <label
      class="cursor-pointer"
      @click="showNewsModal = true"
    >
      <i class="icon-0-icons-dark-newsfeed icon-footer-size" />
    </label>

    <b-modal
      v-model="showNewsModal"
      scrollable
      centered
      hide-footer
      size="notifications"
      title="Noticias"
      hide-backdrop
      content-class="shadow"
    >
      <vue-perfect-scrollbar
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <news-section
          @hidden-news="showNewsModal = $event"
        />
      </vue-perfect-scrollbar>
    </b-modal>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import NewsSection from '@/views/news/NewsSection.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    NewsSection,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showNewsModal: false,
      navMenuItems,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      // eventsNotifications: 'calendar/eventsNotifications',
    }),
  },
  mounted() {
    // document.body.style.overflow = 'hidden'
  },
  destroyed() {
    // document.body.style.overflow = 'visible'
  },
  methods: {
    ...mapActions({
      fetchEventNotifications: 'calendar/fetchEventNotifications',
      addEvent: 'calendar/addEvent',
    }),
    goRoute(option) {
      this.$emit('handle-show-menu', false)
      this.$router.push({ name: option.route })
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';

.modal .modal-content{
  height: 50% !important;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 99%;
  height: 100%;
  max-height: 550px;
}

</style>
