<template>
  <overlay-content :loading="isLoading">
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-center w-100">
          <vc-calendar
            :attributes="attributes"
            :locale="$i18n.locale"
            is-expanded
            color="blue"
            @dayclick="handleEvent"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-center mt-1"
      >
        <b-button
          v-if="checkPermission(['CREATE_EVENTS', 'ALLOW_ALL'])"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          aria-controls="sidebar-add-new-event"
          variant="primary"
          pill
          :style="colorPrimaryBtn"
          @click="isEventHandlerSidebarActive = true"
        >
          <!-- :aria-expanded="String(isEventHandlerSidebarActive)" -->
          {{ $t('generic.addEvent') }}
        </b-button>
      </b-col>
      <!-- Sidebar Overlay -->
      <b-col cols="12">
        <div v-if="currentBreakPoint !== 'xs'">
          <calendar-event-handler
            v-model="isEventHandlerSidebarActive"
            :event="event"
            :user-list="userList"
            :club-info="clubInfo"
            :color-primary-btn="colorPrimaryBtn"
            :check-permission="checkPermission"
            @is-event-handler-sidebar-active="resetForm"
            @remove-event="onRemoveEvent"
            @add-event="onAddEvent"
            @update-event="onUpdateEvent"
          />
        </div>

        <!-- Modal -->
        <div v-if="currentBreakPoint === 'xs'">
          <calendar-event-handler-modal
            v-model="isEventHandlerSidebarActive"
            :event="event"
            :user-list="userList"
            :club-info="clubInfo"
            :check-permission="checkPermission"
            @is-event-handler-sidebar-active="resetForm"
            @remove-event="onRemoveEvent"
            @add-event="onAddEvent"
            @update-event="onUpdateEvent"
          />
        </div>
        <div class="mt-1">
          <!-- @click="handleEventView(item)" -->
          <b-card-actions
            v-for="item in eventsFiltered"
            :key="item.hash"
            :title="item.title"
            action-collapse
            :color="item.color_assigned"
            class="ml-1 mr-1 mb-1 p-0"
          >
            <!-- :sub-title="calculateTime(item.init_date, item.end_date)" -->
            <!-- :sub-title="formatDateToMonthShort(item.init_date)" formatDateTimeRemaining -->
            <p v-if="item.description">
              {{ item.description }}
            </p>
            <small> <b>{{ $t('generic.start') }}</b> {{ item.init_date | formatDateTime }}</small><br>
            <small> <b>{{ $t('generic.end') }}</b> {{ item.end_date | formatDateTime }}</small><br>
            <small
              v-if="item.url"
            > <b>{{ $t('generic.url') }} </b><b-link
              target="_blank"
              :href="item.url"
            >{{ item.url }}</b-link></small><br>
            <small
              v-if="item.location"
            > <b>{{ $t('generic.location') }} </b>{{ item.location }}</small><br>
            <div class="float-right mb-1">
              <overlay-button :loading="loadingBtnDelete">
                <b-button
                  v-if="checkPermission(['DELETE_EVENTS', 'ALLOW_ALL'])"
                  variant="outline-secondary"
                  pill
                  size="sm"
                  @click="onRemoveEvent(item.hash)"
                >
                  <i class="icon-0-icons-dark-trash-can mr-25" />
                  <span class="align-middle">{{ $t('buttons.delete') }}</span>
                </b-button>
              </overlay-button>

              <!-- button delete -->

              <!-- Buttons editar -->
              <overlay-button :loading="loadingBtEdit">
                <b-button
                  v-if="userIdAuth == item.user_hash && checkPermission(['EDIT_EVENTS', 'ALLOW_ALL'])"
                  variant="secondary"
                  pill
                  size="sm"
                  style="margin-left: 10px;"
                  @click="handleEventView(item)"
                >
                  {{ $t('buttons.edit') }}
                </b-button>
              </overlay-button>
            </div>
          </b-card-actions>
        <!-- </vue-perfect-scrollbar> -->
        </div>
      </b-col>
    </b-row>
  </overlay-content>
</template>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { formatDateToMonthShort, formatDate } from '@core/utils/filter'
import checkPermission from '@/auth/permissions'
import clubsService from '@/services/clubsService'
import userService from '@/services/userService'
import OverlayButton from '@/views/partials/OverlayButton.vue'
import OverlayContent from '@/views/partials/OverlayContent.vue'
import BCardActions from '../components/b-card-actions/BCardActions.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import CalendarEventHandlerModal from './calendar-event-handler/CalendarEventHandlerModal.vue'

export default {
  components: {
    // VuePerfectScrollbar,
    BCardActions,
    CalendarEventHandler,
    OverlayButton,
    OverlayContent,
    CalendarEventHandlerModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      eventsFiltered: [],
      isEventHandlerSidebarActive: false,
      date: new Date(),
      userList: [],
      loadingBtEdit: false,
      loadingBtnDelete: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      event: 'calendar/event',
      events: 'calendar/events',
      clubInfo: 'clubInfo',
      currentBreakPoint: 'app/currentBreakPoint',
      colorPrimaryBtn: 'colorPrimaryBtn',
      userIdAuth: 'id',
    }),
    attributes() {
      return [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
        // Attributes for todos
        ...this.events.map(todo => ({
          dates: {
            start: new Date(todo.init_date),
            end: new Date(todo.end_date),
          },
          dot: {
            color: '',
            class: todo.end_date <= new Date() ? 'opacity-75' : '',
            style: {
              background: todo.color_assigned,
            },
          },
          popover: {
            label: todo.title,
            visibility: 'hover',
          },
          customData: todo,
        })),
      ]
    },
  },
  mounted() {
    this.fetchEventsList()
    this.getUsers()
    this.handleEvent({ date: new Date() })
  },
  methods: {
    checkPermission,
    formatDateToMonthShort,
    formatDate,
    // removeTime,
    ...mapActions({
      fetchEventsList: 'calendar/fetchData',
      filterEventsByDate: 'calendar/filterEventsByDate',
      addEvent: 'calendar/add',
      fetchEventById: 'calendar/fetchEventById',
      removeEvent: 'calendar/remove',
      updateEvent: 'calendar/update',
      // getUsersData: 'userStore/getUsersData',
    }),
    async getUsers() {
      if (this.clubInfo.hash) {
        await this.getUsersClub()
      } else {
        await this.getUsersOstrail()
      }
    },
    async getUsersClub() {
      this.isLoading = true
      await clubsService.getUsersByClubId(this.clubInfo.hash).then(response => {
        this.userList = response.data.data.users.filter(user => user.hash !== this.userIdAuth)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      })
    },
    async getUsersOstrail() {
      this.isLoading = true
      await userService.getAdministratorUsers().then(response => {
        this.userList = response.data.data.filter(user => user.hash !== this.userIdAuth)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      })
    },
    removeTime(date = new Date()) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
      )
    },
    handleEvent(event) {
      const currentDate = new Date(event.date)
      this.eventsFiltered = this.events.filter(element => {
        const start = this.removeTime(new Date(element.init_date))
        const end = this.removeTime(new Date(element.end_date))
        return start <= currentDate && end >= currentDate
      })
    },
    async handleEventView(event) {
      this.loadingBtEdit = true
      await this.fetchEventById(event.hash).then(() => {
        this.isEventHandlerSidebarActive = true
        this.loadingBtEdit = false
      }).catch(error => {
        this.loadingBtEdit = false
        this.responseCatch(error)
      })
    },
    async onAddEvent(event) {
      this.isLoading = true
      await this.addEvent(event).then(async () => {
        await this.fetchEventsList()
        this.handleEvent({ date: new Date() })
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      })
    },
    async onUpdateEvent(event) {
      this.isLoading = true
      await this.updateEvent(event).then(async () => {
        this.eventsFiltered = []
        await this.fetchEventsList()
        this.handleEvent({ date: new Date() })
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      })
    },
    async onRemoveEvent(idEvent) {
      this.loadingBtnDelete = true
      await this.removeEvent(idEvent).then(async () => {
        await this.fetchEventsList()
        this.handleEvent({ date: new Date() })
        this.eventsFiltered = []
        this.loadingBtnDelete = false
      }).catch(error => {
        this.loadingBtnDelete = false
        this.responseCatch(error)
      }).finally(() => {
        this.isEventHandlerSidebarActive = false
      })
    },
    resetForm() {
      this.isEventHandlerSidebarActive = false
      this.event.hash = null
      this.event.title = null
      this.event.description = null
      this.event.location = null
      this.event.status = true
      this.event.all_day = false
      this.event.init_date = new Date()
      this.event.end_date = null
      this.event.include_everyone = false
      this.event.url = null
      this.event.user_hash = null
      this.event.include_users = []
      this.event.exclude_users = []
      this.event.club_hash = null
      this.event.color = ''
      this.event.color_hash = null
    },
  },
}
</script>
<style lang="scss">
.b-calendar.calendar-club > .b-calendar-inner {
  width: 370px !important;
}
@media (max-width: 400px) {
  .b-calendar.calendar-club > .b-calendar-inner {
  width: 270px !important;
}
}
.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.vc-container.vc-blue {
  --blue-600: #6abeff;
}
</style>
