<template>
  <div>
    <label
      class="cursor-pointer"
      style="margin-top: 5px;"
      @click="showMessageModal = true"
    >
      <feather-icon
        :badge="15"
        badge-classes="bg-danger"
        class="text-body"
        icon="MessageSquareIcon"
        size="10"
      />
    </label>
    <b-modal
      id="createJobModal"
      v-model="showMessageModal"
      body-class="p-0"
      hide-backdrop
      scrollable
      centered
      hide-footer
      no-close-on-backdrop
      size="notifications"
      content-class="shadow"
      @close="$emit('update:show-message-modal', false)"
    >
      <!-- Message -->
      <message />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Message from '@core/layouts/components/app-message/Message.vue'

export default {
  components: {
    Message,
  },
  // model: {
  //   prop: 'showMessageModal',
  //   event: 'update:show-message-modal',
  // },
  // props: {
  //   showMessageModal: {
  //     type: Boolean,
  //     required: true,
  //   },
  // },
  data() {
    return {
      showMessageModal: false,
    }
  },
  computed: {
    ...mapGetters({
      messagesList: 'message/messagesList',
    }),
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';
.scroll-area {
  position: relative;
  margin: auto;
  width: 99%;
  height: 100%;
  max-height: 550px;
}
 .media-list .media {
  padding: 0.4rem;
  margin-top: 0;
}
 .modal {
  background-color: transparent !important;
  padding: 0px !important;
  max-height: 150px;
}
.modal-body {
  padding: 0px !important;
}
.badge.badge-up {
  position: absolute;
  top: -10px;
  min-width: 1.10rem !important;
  min-height: 1.10rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.786rem;
  line-height: 0.786;
}
</style>
