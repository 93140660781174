var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-1"},[_c('validation-observer',{ref:"createMessage"},[_c('b-form-group',[_c('label',{staticClass:"form-label",attrs:{"for":"message-type"}},[_vm._v(" "+_vm._s(_vm.$t('conversations.labels.howDoYouWant'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"howDoYouWant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"message-type","state":errors.length > 0 ? false : null,"label":"name","reduce":function (option) { return option.id; },"options":_vm.messageTypes,"placeholder":_vm.$t('conversations.placeholder.selectOption')},on:{"input":_vm.assignType},model:{value:(_vm.message.message_type),callback:function ($$v) {_vm.$set(_vm.message, "message_type", $$v)},expression:"message.message_type"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.message.apply_group)?_c('b-form-group',[_c('label',{staticClass:"form-label",attrs:{"for":"group-name"}},[_vm._v(" "+_vm._s(_vm.$t('conversations.labels.groupName'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"groupName","rules":"required|max:50|clubNameRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"group-name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('conversations.placeholder.enterGroupName')},model:{value:(_vm.message.group_name),callback:function ($$v) {_vm.$set(_vm.message, "group_name", $$v)},expression:"message.group_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2708819587)})],1):_vm._e(),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":"","checked":_vm.message.include_everyone,"value":1,"unchecked-value":0},model:{value:(_vm.message.include_everyone),callback:function ($$v) {_vm.$set(_vm.message, "include_everyone", $$v)},expression:"message.include_everyone"}},[_vm._v(" "+_vm._s(_vm.$t('send-everyone'))+" ")])],1),_c('b-form',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-form-group',[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v(" "+_vm._s(_vm.$t('send-to'))+": ")]),_c('validation-provider',{attrs:{"name":"sendTo","rules":_vm.message.include_everyone == 1 ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"multiple":"","label":"name","reduce":function (option) { return option.hash; },"close-on-select":false,"options":_vm.userList,"input-id":"email-to","disabled":_vm.message.include_everyone == 1,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar_media_file = ref.avatar_media_file;
var full_name = ref.full_name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar_media_file == null ? null : _vm.path + avatar_media_file.url_relative}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(full_name))])]}},{key:"selected-option",fn:function(ref){
var avatar_media_file = ref.avatar_media_file;
var full_name = ref.full_name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar_media_file == null ? null : _vm.path + avatar_media_file.url_relative}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(full_name))])]}}],null,true),model:{value:(_vm.message.guests),callback:function ($$v) {_vm.$set(_vm.message, "guests", $$v)},expression:"message.guests"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticClass:"form-label",attrs:{"for":"exclude-guests"}},[_vm._v(" "+_vm._s(_vm.$t('exclude'))+": ")]),_c('v-select',{staticClass:"select-size-sm",attrs:{"multiple":"","close-on-select":false,"options":_vm.userList,"label":"name","reduce":function (option) { return option.hash; },"input-id":"exclude-guests","disabled":_vm.message.include_everyone === 0},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar_media_file = ref.avatar_media_file;
var full_name = ref.full_name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar_media_file == null ? null : _vm.path + avatar_media_file.url_relative}}),_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(full_name))])]}},{key:"selected-option",fn:function(ref){
var avatar_media_file = ref.avatar_media_file;
var full_name = ref.full_name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar_media_file == null ? null : _vm.path + avatar_media_file.url_relative}}),_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(full_name))])]}}]),model:{value:(_vm.message.exclude_guests),callback:function ($$v) {_vm.$set(_vm.message, "exclude_guests", $$v)},expression:"message.exclude_guests"}}),(_vm.errorInUsers)?_c('label',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorInUsers))+" ")]):_vm._e()],1),_c('validation-provider',{attrs:{"rules":"required|max:255","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('generic.message'),"label-for":"event-message"}},[_c('b-form-textarea',{attrs:{"id":"event-message"},model:{value:(_vm.message.content),callback:function ($$v) {_vm.$set(_vm.message, "content", $$v)},expression:"message.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"float-right mb-1 mt-2"},[_c('b-button',{staticStyle:{"margin-left":"17px"},attrs:{"variant":"secondary","pill":""},on:{"click":_vm.cancelMessage}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"margin-left":"17px"},style:(_vm.colorPrimaryBtn),attrs:{"pill":"","variant":"primary","right":""},on:{"click":function($event){$event.preventDefault();return _vm.handleAddMessage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }